import React, { useState, useEffect } from 'react';
import { base, iconBasePath, key } from '../constants/weatherConfig';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import language from '../utils/language';

function Weather() {
    const [weather, setWeather] = useState({
        lat: undefined,
        lon: undefined,
        errorMessage: undefined,
        temperatureC: undefined,
        temperatureF: undefined,
        city: undefined,
        country: undefined,
        humidity: undefined,
        description: undefined,
        icon: '01d',
        sunrise: undefined,
        sunset: undefined,
        errorMsg: undefined,
        currentTime: undefined,
        currentDate: undefined,
    });

    const getWeather = async (lat, lon, lang) => {
        const api_call = await fetch(
            `${base}?lat=${lat}&lon=${lon}&units=metric&APPID=${key}&lang=${lang}`
        ).then(res => res.json());
        setWeather({
            ...weather,
            temperatureC: api_call.main.temp.toFixed(1),
            temperatureF: (api_call.main.temp * 9) / 5 + 32,
            city: api_call.name,
            country: api_call.sys.country,
            humidity: api_call.main.humidity,
            description: api_call.weather[0].description,
            icon: api_call.weather[0].icon,
            sunrise: api_call.sys.sunrise,
            sunset: api_call.sys.sunset,
            currentTime: moment().format('HH:mm'),
            currentDate: moment().format('DD/MM/YYYY'),
        });
    };

    useEffect(() => {
        const getPosition = async () => {
            let languser = language();

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    const lat = position.coords.latitude;
                    const lon = position.coords.longitude;
                    getWeather(lat, lon, languser);
                });
            } else {
                setWeather({
                    ...weather,
                    errorMsg: 'Geolocation is not supported by this browser.',
                });
            }
        };

        getPosition();
    }, []);

    return (
        <div>
            <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component='div' variant='h5'>
                            {weather.temperatureC} °C
                        </Typography>
                        <Typography component='div' variant='subtitle2'>
                            {weather.currentTime} - {weather.currentDate}
                        </Typography>
                        <Typography variant='subtitle1' color='text.secondary' component='div'>
                            {weather.city} - {weather.country}
                        </Typography>
                        <Typography variant='subtitle2' color='text.secondary' component='div'>
                            {weather.description}
                        </Typography>
                    </CardContent>
                </Box>
                <CardMedia
                    component='img'
                    sx={{ width: 151 }}
                    src={`${iconBasePath}${weather.icon}@2x.png`}
                    title='icon weather'
                />
            </Card>
        </div>
    );
}

export default Weather;
