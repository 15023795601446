import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { callMsGraph, getDepartementUser, getMemberOf, getProfilPicture } from '../graph';
import { Card, CardMedia, CardContent, Typography, Divider, Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { List, ListItem, ListItemText } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { ListItemIcon } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import UserStore from '../store/UserStore';
import Weather from './Weather';
import { AdminGroup } from '../constants/AdminGroup';
import { Link } from 'react-router-dom';
import { HomeButton } from './HomeButton';
import { ContactButton } from './ContactButton';
import { AdminButton } from './admin/AdminButton';

function Profil({ isAdmin }) {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const userVejes = UserStore(state => state.users);
    const addUserVejes = UserStore(state => state.addUser);
    const updateUserGroupVejes = UserStore(state => state.updateUser);
    const updateUserDepartementVejes = UserStore(state => state.updateUserDepartement);

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance
            .acquireTokenSilent(request)
            .then(response => {
                callMsGraph(response.accessToken)
                    .then(response => {
                        // if user is already in the store, update it else add it
                        const user = Object.values(userVejes).find(u => u.id === response.id);
                        if (!user && response.id) {
                            addUserVejes({
                                id: response.id,
                                displayName: response.displayName,
                                mail: response.mail,
                                jobTitle: response.jobTitle,
                                givenName: response.givenName,
                                surname: response.surname,
                            });
                        }
                    })
                    .then(() => {
                        getMemberOf(response.accessToken, 'memberOf/$/microsoft.graph.group').then(
                            response => {
                                updateUserGroupVejes({ groupMember: response.value });

                                if (response.value.some(group => group.id === AdminGroup)) {
                                    localStorage.setItem('isAdmin', true);
                                } else {
                                    localStorage.setItem('isAdmin', false);
                                }
                            }
                        );
                    })
                    .then(() => {
                        getProfilPicture(response.accessToken, 'photo/$value').then(response => {
                            setProfilePicture(response);
                        });
                    })
                    .then(() => {
                        getDepartementUser(response.accessToken, '?$select=department').then(
                            response => {
                                //    add new entry in state objet with value departement
                                updateUserDepartementVejes({
                                    department: response.department,
                                });
                            }
                        );
                    });
            })

            .catch(e => {
                instance.acquireTokenPopup(request).then(response => {
                    callMsGraph(response.accessToken).then(response => setGraphData(response));
                });
            });
    }, []);

    return (
        <>
            {userVejes[0] && (
                <div
                    style={{
                        paddingTop: '1rem',
                    }}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia>
                            <Avatar
                                alt='image_profile'
                                src={profilePicture}
                                sx={{ width: 200, height: 200, margin: 'auto' }}
                            />
                        </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                {userVejes[0].givenName} {userVejes[0].surname}
                            </Typography>
                            <Divider />
                            <Typography
                                gutterBottom
                                component='div'
                                sx={{
                                    marginTop: '1rem',
                                    marginBottom: '1rem',
                                }}>
                                <MailIcon /> {userVejes[0].mail}
                            </Typography>
                            <Divider />
                            {/* <p>Groupes :</p>
                            {userVejes[0].groupMember ? (
                                userVejes[0].groupMember.map(group => {
                                    return (
                                        <List key={group.id}>
                                            <ListItem dense key={group.id}>
                                                <ListItemIcon>
                                                    <GroupIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    key={group.id}
                                                    primary={group.displayName}
                                                    secondary={group.id}
                                                />
                                            </ListItem>
                                        </List>
                                    );
                                })
                            ) : (
                                <ul>
                                    <li>Aucun groupe d'appartenance</li>
                                </ul>
                            )}
                            <Divider /> */}
                            <p>Département :</p>
                            {userVejes[0].department ? (
                                <List>
                                    <ListItem dense key={userVejes[0].department}>
                                        <ListItemIcon>
                                            <GroupIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            key={userVejes[0].department}
                                            primary={userVejes[0].department}
                                        />
                                    </ListItem>
                                </List>
                            ) : (
                                <ul>
                                    <li>Aucun Département d'appartenance</li>
                                </ul>
                            )}
                        </CardContent>
                    </Card>
                    <Divider />
                    <Weather />
                    <Divider />
                    <div
                        style={{
                            marginTop: '1rem',
                            marginBottom: '1rem',
                        }}>
                        <Link
                            to='/'
                            style={{
                                textDecoration: 'none',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            <HomeButton />
                        </Link>
                    </div>
                    <div
                        style={{
                            marginTop: '1rem',
                        }}>
                        <Link
                            to='/contact'
                            style={{
                                textDecoration: 'none',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            <ContactButton />
                        </Link>
                    </div>
                    <Link
                        to='/favorites'
                        style={{
                            textDecoration: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        <Button
                            variant='contained'
                            sx={{
                                marginTop: '1rem',
                                marginBottom: '1rem',
                            }}>
                            Favoris Apps
                        </Button>
                    </Link>
                    {isAdmin && (
                        <div
                            style={{
                                marginTop: '1rem',
                            }}>
                            <Link
                                to='/admin'
                                style={{
                                    textDecoration: 'none',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                <AdminButton />
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default Profil;
