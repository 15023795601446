import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import AppCard from './AppCard';
import UserStore from '../store/UserStore';
import Layout from './Layout';
import { BaseURL } from '../constants/BaseUrl';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

export default function AppsList() {
    const userVejes = UserStore(state => state.users);

    const api = axios.create({
        baseURL: BaseURL,
    });

    const [appsList, setAppsList] = useState([]);
    const [allAppList, setAllAppList] = useState([]);
    const [search, setSearch] = useState('');

    // get all apps from db and set them in state, after get department and set it in state to be used in the select menu for departments
    useEffect(() => {
        const fetchData = async () => {
            const res = await api.get('/appsdepartment');
            setAppsList(res.data);
            setAllAppList(res.data);
        };
        fetchData();
    }, []);

    let isAlreadyAdd = [];

    const filterApp = (user, app) => {
        if (user.groupMember) {
            const userGroupMember = user.groupMember.map(group => group.id);

            const appDepartments = app.department_name;
            const isAppAvailable = userGroupMember.some(
                group => appDepartments.includes(group) || appDepartments.includes('all')
            );
            if (isAppAvailable && !isAlreadyAdd.includes(app.id)) {
                isAlreadyAdd.push(app.id);
                return <AppCard key={app.id} app={app} user={user} />;
            }
        }
    };

    const requestSearch = searchedVal => {
        const filteredRows = allAppList.filter(row => {
            return Object.keys(row).some(key =>
                row[key].toString().toLowerCase().includes(searchedVal.toLowerCase())
            );
        });
        setAppsList(filteredRows);
    };

    const handleSearch = e => {
        setSearch(e.target.value);
        requestSearch(e.target.value);
    };

    const clearSearch = () => {
        setSearch('');
        requestSearch('');
    };

    return (
        <Layout>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <TextField
                    autoFocus
                    sx={{
                        marginTop: 15,
                        backgroundColor: 'white',
                    }}
                    label='Seach for an app'
                    id='search-input-app-test'
                    name='search_app'
                    type='text'
                    onChange={handleSearch}
                    value={search}
                    InputProps={
                        search !== '' && {
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={clearSearch}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }
                    }
                />
            </div>
            <Grid
                container
                alignItems='stretch'
                spacing={{ xs: 2, sm: 2, md: 3, lg: 4, xl: 8 }}
                columns={{ xs: 2, sm: 5, md: 8, lg: 10, xl: 12 }}
                sx={{
                    paddingTop: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {userVejes[0] &&
                    userVejes[0].groupMember &&
                    appsList &&
                    appsList.map(app => filterApp(userVejes[0], app))}
            </Grid>
        </Layout>
    );
}
