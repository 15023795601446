import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { SignInButton } from './SignInButton';
import { useIsAuthenticated } from '@azure/msal-react';
import { SignOutButton } from './SignOutButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Profil from './Profil';
import { ContactButton } from './ContactButton';
import UserStore from '../store/UserStore';
import { useLocation } from 'react-router-dom';
import { HomeButton } from './HomeButton';
import { AdminButton } from './admin/AdminButton';
import { AdminGroup } from '../constants/AdminGroup';

const Layout = ({ children }) => {
    const location = useLocation();
    const userVejes = UserStore(state => state.users);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const isAuthenticated = useIsAuthenticated();

    const isAdmin =
        userVejes[0] &&
        userVejes[0].groupMember &&
        userVejes[0].groupMember.some(group => group.id === AdminGroup);

    const drawerWidth = 270;

    const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
        ({ theme, open }) => ({
            flexGrow: 1,
            padding: theme.spacing(4),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: `-${drawerWidth}px`,
            ...(open && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
                padding: theme.spacing(4),
            }),
        })
    );

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: prop => prop !== 'open',
    })(({ theme, open }) => ({
        backgroundColor: '#212b43',
        maxHeight: '64px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));

    return (
        <>
            <Box
                className='AppContainer'
                sx={{
                    display: 'flex',
                }}>
                <CssBaseline />
                <AppBar position='fixed' open={open}>
                    <Toolbar>
                        <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={handleDrawerOpen}
                            edge='start'
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}>
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            sx={{
                                alignItems: 'center',
                                width: 'auto',
                                '@media (max-width: 600px)': {
                                    display: 'none',
                                },
                                '@media (min-width: 600px)': {
                                    display: 'flex',
                                },
                                whiteSpace: 'nowrap',
                            }}
                            variant='h6'
                            component='div'>
                            {userVejes && userVejes[0].displayName
                                ? `Welcome ${userVejes[0].displayName} into `
                                : 'Welcome into'}
                            <img
                                src={process.env.PUBLIC_URL + '/logo_vejes230x60.png'}
                                alt='logo'
                                height={64}
                                style={{
                                    paddingLeft: '10px',
                                }}
                            />
                        </Typography>
                        <Typography
                            sx={{
                                width: '100%',
                                '@media (max-width: 600px)': {
                                    display: 'flex',
                                },
                                '@media (min-width: 600px)': {
                                    display: 'none',
                                },
                            }}
                            variant='h6'
                            component='div'>
                            <img
                                src={process.env.PUBLIC_URL + '/logo_vejes230x60.png'}
                                alt='logo'
                                height={64}
                                style={{
                                    paddingLeft: '10px',
                                }}
                            />
                        </Typography>

                        <Box
                            sx={{
                                '@media (max-width: 1048px)': {
                                    display: 'none',
                                },
                                '@media (min-width: 1048px)': {
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'right',
                                },
                            }}>
                            <HomeButton />
                            <ContactButton />
                            {isAdmin ? <AdminButton /> : null}

                            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant='persistent'
                    anchor='left'
                    open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <AuthenticatedTemplate>
                        <Profil isAdmin={isAdmin} />
                    </AuthenticatedTemplate>
                </Drawer>
                <Main open={open}>
                    <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <p
                            style={{
                                textAlign: 'center',
                                marginTop: '50px',
                            }}>
                            You are not signed in! Please sign in.
                        </p>
                    </UnauthenticatedTemplate>
                </Main>
            </Box>
        </>
    );
};

export default Layout;
