import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FormControl, FormHelperText, InputLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BaseURL } from '../../../../constants/BaseUrl';

const CssFormHelperText = styled(FormHelperText)({
    color: 'red',
    fontSize: '1rem',
});

const CssTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: 'white',
    },
    // width of input field in create app form 400px
    width: '400px',
    '& label.Mui-focused': {
        color: 'black',
    },
    '& label': {
        color: 'black',
        fontStyle: 'italic',
    },
    '& .MuiInputLabel-outlined': {
        fontWeight: 'bold',

        fontSize: '17px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'gray',
        },
        '&:hover fieldset': {
            borderColor: 'white',
            border: '1px solid black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            border: '1px solid black',
        },
    },
});

const CssSelect = styled(Select)({
    '& .MuiSelect-select': {
        backgroundColor: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray',
    },
    width: '400px',
    height: '56px',
});

const api = axios.create({
    baseURL: BaseURL,
});

const defaultValues = {
    first_name: '',
    last_name: '',
    email: '',
    app_id: '',
    app_name: '',
};

const CreateTechnicalSupport = () => {
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(defaultValues);
    const [apps, setApps] = useState([]);
    const [error, setError] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        // get all categories from db and set them in state to be used in the select menu for categories
        api.get('/apps').then(res => {
            setApps(res.data);
        });
        if (localStorage.getItem('isAdmin') === 'true') {
            setIsAdmin(true);
        }
    }, []);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    }; // await post the file logo and get the file name back to set it in the formValues state

    const handleAppChange = event => {
        // with the name of the category, find the id of the category and set it in the formValues state to be sent to the db
        const app = apps.find(app => app.name === event.target.value);
        setFormValues({
            ...formValues,
            app_id: app.id,
            app_name: app.name,
        });
    };

    console.log(formValues);

    const handleSubmit = e => {
        e.preventDefault();

        if (formValues.first_name === '') {
            // setError in array with key and value to be used in the form to display the error
            return setError([{ key: 'first_name', value: 'First name is required' }]);
        }
        if (formValues.last_name === '') {
            return setError([{ key: 'last_name', value: 'Last name is required' }]);
        }
        if (formValues.email === '') {
            return setError([{ key: 'email', value: 'Email is required' }]);
        }
        if (formValues.app_id === '') {
            return setError([{ key: 'app_id', value: 'App is required' }]);
        }

        // if all the required fields are filled, reset error state and submit the form
        if (
            formValues.first_name !== '' &&
            formValues.last_name !== '' &&
            formValues.email !== '' &&
            formValues.app_id !== ''
        ) {
            setError([]);
            api.post('/technicalsupport', formValues)
                .then(res => {
                    // if res status 200 set message to the user that the app is created else set error message to the user
                    if (res.status === 200) {
                        alert('Technical Support created successfully');
                        setFormValues(defaultValues);
                        return res;
                    } else {
                        alert('An error occured');
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    return (
        <>
            {isAdmin ? (
                <>
                    <Typography
                        variant='h4'
                        sx={{
                            mb: 2,
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        Create Technical Support
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid
                            container
                            alignItems='center'
                            justify='center'
                            direction='column'
                            spacing={2}>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(e => e.key === 'first_name') && {
                                        error: true,
                                    })}
                                    id='firstname-input'
                                    name='first_name'
                                    label='First Name'
                                    type='text'
                                    value={formValues.first_name}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'first_name') && (
                                    <CssFormHelperText id='firstname-error-text'>
                                        {error.find(e => e.key === 'first_name').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(e => e.key === 'last_name') && {
                                        error: true,
                                    })}
                                    id='lastname-input'
                                    name='last_name'
                                    label='Last Name'
                                    type='text'
                                    value={formValues.last_name}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'last_name') && (
                                    <CssFormHelperText id='lastname-error-text'>
                                        {error.find(e => e.key === 'last_name').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(e => e.key === 'email') && {
                                        error: true,
                                    })}
                                    id='email-input'
                                    name='email'
                                    label='Email'
                                    type='text'
                                    value={formValues.email}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'email') && (
                                    <CssFormHelperText id='email-error-text'>
                                        {error.find(e => e.key === 'email').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>

                            <Grid item>
                                <FormControl
                                    {...(error.find(e => e.key === 'app_id') && {
                                        error: true,
                                    })}
                                    sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id='app'>App for technical support</InputLabel>
                                    <CssSelect
                                        labelId='app'
                                        id='demo-simple-select'
                                        name='app_id'
                                        value={formValues.app_name}
                                        onChange={handleAppChange}>
                                        {apps.length > 0 &&
                                            apps.map((app, index) => (
                                                <MenuItem key={index} value={app.name}>
                                                    {app.name}
                                                </MenuItem>
                                            ))}
                                    </CssSelect>
                                </FormControl>
                            </Grid>
                            {error.find(e => e.key === 'app_id') && (
                                <CssFormHelperText
                                    sx={{
                                        color: 'red',
                                        fontSize: '16px',
                                    }}>
                                    {error.find(e => e.key === 'app_id').value}
                                </CssFormHelperText>
                            )}

                            {
                                // if almost one field are different that the default value show the button else don't show the button
                                formValues.first_name !== defaultValues.first_name ||
                                formValues.last_name !== defaultValues.last_name ||
                                formValues.email !== defaultValues.email ? (
                                    <div
                                        style={{
                                            padding: '20px',
                                        }}>
                                        <Button type='submit' variant='contained' color='primary'>
                                            Submit
                                        </Button>
                                    </div>
                                ) : (
                                    ''
                                )
                            }
                        </Grid>
                    </form>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '20px',
                        }}>
                        <Link
                            to='/admin'
                            style={{
                                textDecoration: 'none',
                            }}>
                            <Button variant='contained' color='error'>
                                Back to admin page
                            </Button>
                        </Link>
                    </div>
                </>
            ) : (
                <h1>Reserved for admin</h1>
            )}
        </>
    );
};

export default CreateTechnicalSupport;
