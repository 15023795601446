import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { isAndroid, isChrome, isDesktop, isFirefox, isIOS, isMobile } from 'react-device-detect';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { urlFormGLPI } from '../../../constants/glpi';
import language from '../../../utils/language';
import { BaseURL } from '../../../constants/BaseUrl';

const api = axios.create({
    baseURL: BaseURL,
});

const ViewApp = () => {
    const [openTech, setOpenTech] = useState(false);
    const [openSale, setOpenSale] = useState(false);
    const [langBrowser, setlangBrowser] = useState('en');
    const [salesSupport, setSalesSupport] = useState([]);
    const [techSupport, setTechSupport] = useState([]);
    const [app, setApp] = useState('');
    const { id } = useParams();
    const [isAdmin, setIsAdmin] = useState(false);

    const handleClickTech = () => {
        setOpenTech(!openTech);
    };
    const handleClickSale = () => {
        setOpenSale(!openSale);
    };

    useEffect(() => {
        let lang = language();
        setlangBrowser(lang);

        const fetchApp = async () => {
            const res = await api.get(`/apps/${id}`);
            setApp(res.data[0]);
            const resSales = await api.get(`/salesupport/app/${res.data[0].id}`);
            setSalesSupport(resSales.data);
            const resTech = await api.get(`/technicalsupport/app/${res.data[0].id}`);
            setTechSupport(resTech.data);
        };
        fetchApp();

        if (localStorage.getItem('isAdmin') === 'true') {
            setIsAdmin(true);
        }
    }, []);

    let urlApp =
        app.androi_app != '' && isAndroid
            ? app.androi_app
            : app.ios_app != '' && isIOS
            ? app.ios_app
            : app.url;

    const checkUrlApp = app => {
        if (app.android_app != '' && isAndroid) {
            return app.android_app;
        } else if (app.ios_app != '' && isIOS) {
            return app.ios_app;
        } else {
            return app.url;
        }
    };

    return (
        <>
            {isAdmin && app && (
                <div
                    style={{
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            maxWidth: 400,
                            minHeight: 400,
                        }}>
                        <CardActionArea href={checkUrlApp(app)} target='_blank'>
                            <CardMedia
                                component='img'
                                height='140'
                                image={'/' + app.logo}
                                alt={app.logo}
                                sx={{
                                    objectFit: 'contain',
                                    padding: '10px',
                                }}
                            />
                        </CardActionArea>
                        <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography gutterBottom variant='h5' component='div'>
                                    {langBrowser === 'fr' && app.name_fr != ''
                                        ? app.name_fr
                                        : app.name}
                                </Typography>
                            </div>
                            <Typography variant='body2' color='text.secondary'>
                                {app.vendor}
                            </Typography>
                        </CardContent>
                        <div>
                            <List
                                sx={{
                                    width: '100%',
                                    maxWidth: 360,
                                    bgcolor: 'background.paper',
                                }}
                                component='nav'>
                                {(app.tech_support_saas === '' ||
                                    app.tech_support_saas === null ||
                                    app.tech_support_saas === undefined) && (
                                    <ListItem
                                        sx={{
                                            display: 'block',
                                        }}>
                                        <ListItemButton onClick={handleClickTech}>
                                            <ListItemText
                                                primary={
                                                    langBrowser === 'fr'
                                                        ? 'Contact Technique'
                                                        : 'Technical Contact'
                                                }
                                            />
                                            {openTech ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={openTech} timeout='auto' unmountOnExit>
                                            <List component='div' disablePadding>
                                                {techSupport.map((tech, index) => (
                                                    <>
                                                        <Typography
                                                            variant='body1'
                                                            sx={{
                                                                padding: '10px',
                                                                fontWeight: 'bold',
                                                            }}>
                                                            {tech.first_name} {tech.last_name}
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                            }}>
                                                            {tech.email != '' && (
                                                                <ListItemButton
                                                                    sx={{ pl: 4 }}
                                                                    component='a'
                                                                    href={'mailto:' + tech.email}>
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent:
                                                                                'center',
                                                                        }}>
                                                                        <EmailIcon />
                                                                    </ListItemIcon>
                                                                </ListItemButton>
                                                            )}
                                                            {tech.email === '' &&
                                                                app.tech_support_saas != '' &&
                                                                app.tech_support_saas != null && (
                                                                    <>
                                                                        <ListItemButton
                                                                            sx={{ pl: 4 }}
                                                                            component='a'
                                                                            target='_blank'
                                                                            href={
                                                                                app.tech_support_saas
                                                                            }>
                                                                            <>
                                                                                <ListItemIcon
                                                                                    sx={{
                                                                                        display:
                                                                                            'flex',
                                                                                        alignItems:
                                                                                            'center',
                                                                                        justifyContent:
                                                                                            'center',
                                                                                    }}>
                                                                                    <ContactSupportIcon />
                                                                                </ListItemIcon>
                                                                            </>
                                                                        </ListItemButton>
                                                                    </>
                                                                )}
                                                            {tech.email != '' &&
                                                                app.tech_support_saas === '' && (
                                                                    <ListItemButton
                                                                        sx={{ pl: 4 }}
                                                                        component='a'
                                                                        target='_blank'
                                                                        href={urlFormGLPI}>
                                                                        <>
                                                                            <ListItemIcon
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    justifyContent:
                                                                                        'center',
                                                                                }}>
                                                                                <ContactSupportIcon />
                                                                            </ListItemIcon>
                                                                        </>
                                                                    </ListItemButton>
                                                                )}
                                                        </div>
                                                    </>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </ListItem>
                                )}

                                {app.tech_support_saas != '' &&
                                    app.tech_support_saas != null &&
                                    app.tech_support_saas != undefined && (
                                        <ListItem
                                            sx={{
                                                display: 'block',
                                            }}>
                                            <ListItemButton onClick={handleClickTech}>
                                                <ListItemText
                                                    primary={
                                                        langBrowser === 'fr'
                                                            ? 'Site Support Technique'
                                                            : 'Technical Support Site'
                                                    }
                                                />
                                                {openTech ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={openTech} timeout='auto' unmountOnExit>
                                                <List component='div' disablePadding>
                                                    <>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                            }}>
                                                            <>
                                                                <ListItemButton
                                                                    sx={{
                                                                        width: '100%',
                                                                    }}
                                                                    component='a'
                                                                    target='_blank'
                                                                    href={app.tech_support_saas}>
                                                                    <>
                                                                        <ListItemIcon
                                                                            sx={{
                                                                                width: '100%',
                                                                                display: 'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                                justifyContent:
                                                                                    'center',
                                                                            }}>
                                                                            <ContactSupportIcon />
                                                                        </ListItemIcon>
                                                                    </>
                                                                </ListItemButton>
                                                            </>
                                                        </div>
                                                    </>
                                                </List>
                                            </Collapse>
                                        </ListItem>
                                    )}

                                <ListItem
                                    sx={{
                                        display: 'block',
                                    }}>
                                    <ListItemButton onClick={handleClickSale}>
                                        <ListItemText
                                            primary={
                                                langBrowser === 'fr'
                                                    ? ' Contact Commercial'
                                                    : 'Commercial Contact'
                                            }
                                        />
                                        {openSale ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={openSale} timeout='auto' unmountOnExit>
                                        <List component='div' disablePadding>
                                            {salesSupport.map((sale, index) => (
                                                <>
                                                    <Typography
                                                        variant='body1'
                                                        sx={{
                                                            padding: '10px',
                                                            fontWeight: 'bold',
                                                        }}>
                                                        {sale.first_name} {sale.last_name}
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                        }}>
                                                        {sale.email != '' && (
                                                            <>
                                                                <ListItemButton
                                                                    sx={{ pl: 4 }}
                                                                    component='a'
                                                                    href={'mailto:' + sale.email}>
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent:
                                                                                'center',
                                                                        }}>
                                                                        <EmailIcon />
                                                                    </ListItemIcon>
                                                                </ListItemButton>

                                                                <ListItemButton
                                                                    sx={{ pl: 4 }}
                                                                    component='a'
                                                                    target='_blank'
                                                                    href={urlFormGLPI}>
                                                                    <>
                                                                        <ListItemIcon
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                                justifyContent:
                                                                                    'center',
                                                                            }}>
                                                                            <ContactSupportIcon />
                                                                        </ListItemIcon>
                                                                    </>
                                                                </ListItemButton>
                                                            </>
                                                        )}
                                                        {!sale.email &&
                                                            app.sales_support_saas != '' && (
                                                                <>
                                                                    <ListItemButton
                                                                        sx={{ pl: 4 }}
                                                                        component='a'
                                                                        target='_blank'
                                                                        href={
                                                                            app.sales_support_saas
                                                                        }>
                                                                        <ListItemIcon
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                                justifyContent:
                                                                                    'center',
                                                                            }}>
                                                                            <ContactSupportIcon />
                                                                        </ListItemIcon>
                                                                    </ListItemButton>
                                                                </>
                                                            )}
                                                        {sale.email != '' &&
                                                            app.sales_support_saas === '' && (
                                                                <ListItemButton
                                                                    sx={{ pl: 4 }}
                                                                    component='a'
                                                                    target='_blank'
                                                                    href={urlFormGLPI}>
                                                                    <>
                                                                        <ListItemIcon
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                                justifyContent:
                                                                                    'center',
                                                                            }}>
                                                                            <ContactSupportIcon />
                                                                        </ListItemIcon>
                                                                    </>
                                                                </ListItemButton>
                                                            )}
                                                    </div>
                                                </>
                                            ))}
                                        </List>
                                    </Collapse>
                                </ListItem>
                            </List>
                        </div>
                    </Card>
                </div>
            )}
        </>
    );
};

export default ViewApp;
