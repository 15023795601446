export const msalConfig = {
    auth: {
        clientId: '2b9cce06-fdcd-44a4-b52a-2798a29fdfb9',
        authority: 'https://login.microsoftonline.com/1d21cde0-9fbd-4077-9738-d4757f994dcd', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: 'https://intranet.vejes.com/',
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['User.Read.All', 'GroupMember.Read.All', 'Group.Read.All'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me/',
};
