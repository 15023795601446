import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import ContactCard from './ContactCard';
import Layout from './Layout';
import UserStore1 from '../store/UserStore';
import { BaseURL } from '../constants/BaseUrl';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const ContactList = () => {
    const userVejes = UserStore1(state => state.users);

    const api = axios.create({
        baseURL: BaseURL,
    });

    const [contactsList, setContactsList] = useState([]);
    const [allContactList, setAllContactList] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            api.get('/contact/department').then(res => {
                setContactsList(res.data);
                setAllContactList(res.data);
            });
        };
        fetchData();
    }, []);

    let isAlreadyAdd = [];

    const filterContact = (user, contact) => {
        if (user.groupMember) {
            const userGroupMember = user.groupMember.map(group => group.id);
            console.log(userGroupMember);
            const contactDepartments = contact.department_name;

            const isContactAvailable = userGroupMember.some(
                group => contactDepartments.includes(group) || contactDepartments.includes('all')
            );
            if (isContactAvailable && !isAlreadyAdd.includes(contact.id)) {
                isAlreadyAdd.push(contact.id);
                return <ContactCard key={contact.id} contact={contact} />;
            }
        }
    };

    const requestSearch = searchedVal => {
        const filteredRows = allContactList.filter(row => {
            return Object.keys(row).some(key =>
                row[key].toString().toLowerCase().includes(searchedVal.toLowerCase())
            );
        });
        setContactsList(filteredRows);
    };

    const handleSearch = e => {
        setSearch(e.target.value);
        requestSearch(e.target.value);
    };

    const clearSearch = () => {
        setSearch('');
        requestSearch('');
    };

    return (
        <Layout>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <TextField
                    autoFocus
                    sx={{
                        marginTop: 15,
                        backgroundColor: 'white',
                    }}
                    label='Seach for an contact'
                    id='search-input-app-test'
                    name='search_app'
                    type='text'
                    onChange={handleSearch}
                    value={search}
                    InputProps={
                        search !== '' && {
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={clearSearch}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }
                    }
                />
            </div>
            <Grid
                container
                alignItems='stretch'
                spacing={{ xs: 2, sm: 2, md: 3, lg: 4, xl: 8 }}
                columns={{ xs: 2, sm: 5, md: 8, lg: 10, xl: 12 }}
                sx={{
                    paddingTop: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {userVejes[0] &&
                    userVejes[0].groupMember &&
                    contactsList &&
                    contactsList.map(contact => filterContact(userVejes[0], contact))}
            </Grid>
        </Layout>
    );
};

export default ContactList;
