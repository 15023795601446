import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios from 'axios';
import { Button, InputAdornment, TableHead, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import { BaseURL } from '../../../constants/BaseUrl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor with primary color
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        // font size 16 px and font weight 600
        fontSize: 16,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label='first page'>
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label='previous page'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='next page'>
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='last page'>
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const api = axios.create({
    baseURL: BaseURL,
});

export default function CategoryTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [category, setCategory] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        api.get('/categories')
            .then(res => {
                setCategory(res.data);
                setAllCategories(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    const requestSearch = searchedVal => {
        const filteredRows =
            allCategories &&
            allCategories.filter(row => {
                // il all row find if a velue is equal to searchedVal
                return Object.keys(row).some(key =>
                    row[key].toString().toLowerCase().includes(searchedVal.toLowerCase())
                );
            });
        setCategory(filteredRows);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - category.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = e => {
        setSearch(e.target.value);
        requestSearch(e.target.value);
    };

    const clearSearch = () => {
        setSearch('');
        requestSearch('');
    };

    const deleteCategory = id => {
        api.delete(`/categories/${id}`)
            .then(res => {
                setCategory(category.filter(category => category.id !== id));
                setAllCategories(allCategories.filter(category => category.id !== id));
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <>
            <Paper>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                    <Typography variant='h6' component='div' sx={{ p: 2 }}>
                        All Categories
                    </Typography>

                    <TextField
                        sx={{ width: '50%' }}
                        label='Seach...'
                        id='search-input'
                        name='search'
                        type='text'
                        onChange={handleSearch}
                        value={search}
                        InputProps={
                            search !== '' && {
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={clearSearch}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }
                        }
                    />
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500 }} aria-label='custom pagination table'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell align='right'></StyledTableCell>
                                <StyledTableCell align='right'></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? category.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                  )
                                : category
                            ).map(row => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell component='th' scope='row'>
                                        {row.name}
                                    </StyledTableCell>

                                    <StyledTableCell align='right'>
                                        <Link
                                            style={{ textDecoration: 'none' }}
                                            to={`/categories/${row.id}/update`}>
                                            <Button variant='contained'>Update</Button>
                                        </Link>
                                    </StyledTableCell>

                                    <StyledTableCell
                                        align='right'
                                        sx={{
                                            width: '100px',
                                        }}>
                                        <Button
                                            variant='contained'
                                            color='error'
                                            onClick={() => {
                                                deleteCategory(row.id);
                                            }}>
                                            Delete
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={category.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}
