import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import AppCard from './AppCard';
import UserStore from '../store/UserStore';
import Layout from './Layout';
import { BaseURL } from '../constants/BaseUrl';

export default function AppsListFavorite() {
    const userVejes = UserStore(state => state.users);

    const api = axios.create({
        baseURL: BaseURL,
    });

    const [appsList, setAppsList] = useState([]);
    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        if (userVejes[0]) {
            api.get(`apps/favorite/user/${userVejes[0].id}`).then(res => {
                setAppsList(res.data);
                setIsFavorite([
                    ...res.data.map(app => {
                        // return the app id and favorite to true
                        return { id: app.id, favorite: true };
                    }),
                ]);
            });
        }
    }, [userVejes[0]]);

    let isAlreadyAdd = [];

    console.log('appsList', appsList);

    const filterApp = (user, app) => {
        if (user.groupMember) {
            const userGroupMember = user.groupMember.map(group => group.id);

            const appDepartments = app.department_name;
            const isAppAvailable = userGroupMember.some(
                group => appDepartments.includes(group) || appDepartments.includes('all')
            );
            if (isAppAvailable && !isAlreadyAdd.includes(app.id)) {
                isAlreadyAdd.push(app.id);
                return <AppCard key={app.id} app={app} user={user} />;
            }
        }
    };
    console.log(isFavorite);
    return (
        <Layout>
            <Grid
                container
                alignItems='stretch'
                spacing={{ xs: 2, sm: 2, md: 3, lg: 4, xl: 8 }}
                columns={{ xs: 2, sm: 5, md: 8, lg: 10, xl: 12 }}
                sx={{
                    paddingTop: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {userVejes[0] &&
                    userVejes[0].groupMember &&
                    appsList &&
                    appsList.map(app => filterApp(userVejes[0], app))}
            </Grid>
        </Layout>
    );
}
