import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FormHelperText, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { BaseURL } from '../../../constants/BaseUrl';

const CssFormHelperText = styled(FormHelperText)({
    color: 'red',
    fontSize: '1rem',
});

const CssTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: 'white',
    },
    // width of input field in create app form 400px
    width: '400px',
    '& label.Mui-focused': {
        color: 'black',
    },
    '& label': {
        color: 'black',
        fontStyle: 'italic',
    },
    '& .MuiInputLabel-outlined': {
        fontWeight: 'bold',

        fontSize: '17px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'gray',
        },
        '&:hover fieldset': {
            borderColor: 'white',
            border: '1px solid black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            border: '1px solid black',
        },
    },
});

const api = axios.create({
    baseURL: BaseURL,
});

const defaultValues = {
    department_id: '',
    name: '',
};

const UpdateDepartment = () => {
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(defaultValues);
    const [error, setError] = useState([]);
    const { id } = useParams();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const res = await api.get(`/department/${id}`);
            setFormValues(res.data[0]);
        };
        fetchData();

        if (localStorage.getItem('isAdmin') === 'true') {
            setIsAdmin(true);
        }
    }, [id]);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    }; // await post the file logo and get the file name back to set it in the formValues state

    const handleSubmit = e => {
        e.preventDefault();

        if (formValues.department_id === '') {
            // setError in array with key and value to be used in the form to display the error
            return setError([{ key: 'department_id', value: 'Department ID is required' }]);
        }

        if (formValues.name === '') {
            // setError in array with key and value to be used in the form to display the error
            return setError([{ key: 'name', value: 'Name is required' }]);
        }

        if (formValues.department_id !== '' && formValues.name !== '') {
            setError([]);
            api.put(`/department/${id}`, formValues)
                .then(res => {
                    // if res status 200 set message to the user that the app is created else set error message to the user
                    if (res.status === 200) {
                        alert(res.data);
                        if (res.data === 'Department updated...') {
                            navigate('/admin');
                        }

                        // alert('Department updated successfully');
                        // navigate('/admin');
                        // return res;
                    } else {
                        alert('An error occured');
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    return (
        <>
            {isAdmin ? (
                <>
                    <Typography
                        variant='h4'
                        sx={{
                            mb: 2,
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        Update Department
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid
                            container
                            alignItems='center'
                            justify='center'
                            direction='column'
                            spacing={2}>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(e => e.key === 'name') && {
                                        error: true,
                                    })}
                                    id='departmentname-input'
                                    name='name'
                                    label='Department Name'
                                    type='text'
                                    value={formValues.name}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'name') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'name').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(e => e.key === 'department_id') && {
                                        error: true,
                                    })}
                                    id='departmentid-input'
                                    name='department_id'
                                    label='Department ID'
                                    type='text'
                                    value={formValues.department_id}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'department_id') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'department_id').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>

                            {
                                // if almost one field are different that the default value show the button else don't show the button
                                formValues.department_id !== defaultValues.department_id ? (
                                    <div
                                        style={{
                                            padding: '20px',
                                        }}>
                                        <Button type='submit' variant='contained' color='primary'>
                                            Update
                                        </Button>
                                    </div>
                                ) : (
                                    ''
                                )
                            }
                        </Grid>
                    </form>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '20px',
                        }}>
                        <Link
                            to='/admin'
                            style={{
                                textDecoration: 'none',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '20px',
                                }}>
                                <Button variant='contained' color='error'>
                                    Back to admin page
                                </Button>
                            </div>
                        </Link>
                    </div>
                </>
            ) : (
                <h1>Reserved for Admin</h1>
            )}
        </>
    );
};

export default UpdateDepartment;
