import { graphConfig } from './authConfig';

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return await fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getProfilPicture(accessToken, endpoint) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return fetch(`${graphConfig.graphMeEndpoint}${endpoint}`, options).then(async response => {
        const url = window.URL || window.webkitURL;
        const blobUrl = url.createObjectURL(await response.blob());
        return blobUrl;
    });
}

export async function getMemberOf(accessToken, endpoint) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return fetch(`${graphConfig.graphMeEndpoint}${endpoint}`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getDepartementUser(accessToken, endpoint) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return fetch(`${graphConfig.graphMeEndpoint}${endpoint}`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
