import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { Typography } from '@mui/material';
import SelectReact from 'react-select';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { BaseURL } from '../../../constants/BaseUrl';

const CssFormHelperText = styled(FormHelperText)({
    color: 'red',
    fontSize: '1rem',
});

const CssTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: 'white',
    },
    // width of input field in create app form 400px
    width: '400px',
    '& label.Mui-focused': {
        color: 'black',
    },
    '& label': {
        color: 'black',
        fontStyle: 'italic',
    },
    '& .MuiInputLabel-outlined': {
        fontWeight: 'bold',

        fontSize: '17px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'gray',
        },
        '&:hover fieldset': {
            borderColor: 'white',
            border: '1px solid black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            border: '1px solid black',
        },
    },
});

const CssSelect = styled(Select)({
    '& .MuiSelect-select': {
        backgroundColor: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray',
    },
    width: '400px',
    height: '56px',
});

const customStyles = {
    control: (base, state) => ({
        ...base,
        border: '1px solid gray',
        '&:hover': {
            border: '1px solid black',
        },
        '&:focus': {
            border: '1px solid black',
        },
        minheight: '56px',
        maxWidth: '400px',
        width: '400px',
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? 'black' : 'white',
        '&:hover': {
            backgroundColor: 'black',
            color: 'white',
        },
        // width size of the biggest size of the options
        width: '350px',
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'black',
    }),

    multiValue: (provided, state) => ({
        ...provided,

        backgroundColor: '#f5f5f5',
        color: 'black',

        border: '1px solid gray',
        // no display on the same line
    }),
};

const api = axios.create({
    baseURL: BaseURL,
});

const defaultValues = {
    name: '',
    name_fr: '',
    vendor: '',
    android_app: '',
    ios_app: '',
    logo: '',
    logo_upload: '',
    url: '',
    category_id: '',
    category_name: '',
    tech_support_saas: '',
    windows_app: '',
};

const UpdateApp = () => {
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(defaultValues);
    const [image, setImage] = useState({ preview: '', data: '' });
    const [categories, setCategories] = useState([]);
    const [imageUpload, setImageUpload] = useState(false);
    const [error, setError] = useState([]);
    const [department, setDepartment] = useState([]);
    const [selected, setSelected] = useState([]);
    const { id } = useParams();
    const [isAdmin, setIsAdmin] = useState(false);

    const location = useLocation();
    console.log(location, ' useLocation Hook');

    useEffect(() => {
        // get the app by id with useParams hook
        const fetchData = async () => {
            const res = await api.get(`/apps/${id}`);
            const selectedDepartments = res.data.map(department => {
                // return in array of objects
                return { value: department.department_id, label: department.department_name };
            });
            setSelected(selectedDepartments);
            // console.log(selectedDepartments);
            // set the form values with the first element of the array of apps returned by the api call
            setFormValues(res.data[0]);
            // set the image preview with the image url returned by the api call
            setImage({ preview: `/${res.data[0].logo}`, data: '' });
        };
        fetchData();

        api.get('/categories').then(res => {
            setCategories(res.data);
        });
        api.get('/department').then(res => {
            // update state who for each department in the db department_id is label and id is value for use react select
            setDepartment(
                res.data.map(department => {
                    return {
                        label: department.name,
                        value: department.id,
                    };
                })
            );
        });
        if (localStorage.getItem('isAdmin') === 'true') {
            setIsAdmin(true);
        }
    }, [id]);

    const handleChangeReactSelect = selectedOption => {
        setSelected(selectedOption);
    };

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    }; // await post the file logo and get the file name back to set it in the formValues state

    const handleCategoryChange = event => {
        // with the name of the category, find the id of the category and set it in the formValues state to be sent to the db
        const category = categories.find(category => category.name === event.target.value);
        setFormValues({
            ...formValues,
            category_id: category.id,
            category_name: category.name,
        });
    };

    // const handleChangeDepartment = event => {
    //     setSelected(event.target.value);
    // };

    // first upload the file to the server and get the file name back to set it in the formValues state "logo_upload" and after submit the form to the db with formValues updated with the file name
    const handleFileUpload = async e => {
        console.log(e);

        // create previw for the image
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            });
        }
        const file = e.target.files[0];
        if (file.size > 1024 * 1024) return alert('Size too large!');
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            setImage({ preview: '', data: '' });
            return alert('File format is incorrect.');
        }
        const formData = new FormData();
        formData.append('file', file);
        const res = await api.post('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        setFormValues({
            ...formValues,
            logo: res.data.saveAs,
        });
        setImageUpload(true);
    };

    // console.log(formValues);

    const handleSubmit = e => {
        e.preventDefault();
        // console.log('toto' + selected);
        if (formValues.name === '') {
            // setError in array with key and value to be used in the form to display the error
            return setError([{ key: 'name', value: 'App name is required' }]);
        }
        if (formValues.category_id === '') {
            // console.log('category_id');
            return setError([{ key: 'category_id', value: 'Category is required' }]);
        }
        if (formValues.logo === '') {
            return setError([{ key: 'logo', value: 'Logo is required' }]);
        }
        if (formValues.vendor === '') {
            return setError([{ key: 'vendor', value: 'Vendor is required' }]);
        }
        if (selected.length === 0) {
            return setError([{ key: 'department', value: 'Department is required' }]);
        }
        api.get(`/apps/name/${formValues.name}`).then(res => {
            if (res.data.length > 0) {
                return setError([{ key: 'name', value: 'App name already exists' }]);
            }
        });

        // if all the required fields are filled, reset error state and submit the form
        if (
            formValues.name !== '' &&
            formValues.category_id !== '' &&
            formValues.logo !== '' &&
            formValues.vendor !== '' &&
            selected.length !== 0
        ) {
            setError([]);
            api.put(`/apps/${id}`, {
                ...formValues,
            }).then(res => {
                if (res.status === 200) {
                    api.get(`/app_department/${id}`)
                        .then(res => {
                            res.data.forEach(app_department => {
                                if (
                                    !selected.find(
                                        department =>
                                            department.value === app_department.department_id
                                    )
                                ) {
                                    api.delete(
                                        `/app_department/${id}/${app_department.department_id}`
                                    );
                                }
                            });
                            selected.forEach(department => {
                                if (
                                    !res.data.find(
                                        app_department =>
                                            app_department.department_id === department.value
                                    )
                                ) {
                                    api.post('/app_department', {
                                        app_id: id,
                                        department_id: department.value,
                                    });
                                }
                            });
                        })
                        .then(() => {
                            alert('App updated successfully');
                            navigate(`/admin`);
                        })
                        .catch(err => console.log(err));
                }
            });
        }
    };

    return (
        <>
            {isAdmin ? (
                <>
                    <Typography
                        variant='h4'
                        sx={{
                            mb: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '35px',
                        }}>
                        Update App
                    </Typography>
                    <form
                        style={{
                            paddingTop: '50px',
                        }}
                        onSubmit={handleSubmit}>
                        <Grid
                            container
                            alignItems='center'
                            justify='center'
                            direction='column'
                            spacing={2}>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(e => e.key === 'name') && {
                                        error: true,
                                    })}
                                    id='name-input'
                                    name='name'
                                    label='Name'
                                    type='text'
                                    value={formValues.name}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'name') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'name').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    id='namefr-input'
                                    name='name_fr'
                                    label='Name FR'
                                    type='text'
                                    value={formValues.name_fr}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(e => e.key === 'category_id') && {
                                        error: true,
                                    })}
                                    id='vendor-input'
                                    name='vendor'
                                    label='Vendor'
                                    type='text'
                                    value={formValues.vendor}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'vendor') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'vendor').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>

                            <Grid item>
                                <CssTextField
                                    {...(error.find(e => e.key === 'logo_upload') && {
                                        error: true,
                                    })}
                                    id='logo-input'
                                    name='logo'
                                    type='file'
                                    value={''}
                                    onChange={handleFileUpload}
                                />
                                {error.find(e => e.key === 'logo_upload') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'logo_upload').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingTop: '10px',
                                }}>
                                {image.preview !== '' && (
                                    <img
                                        src={image.preview}
                                        style={{
                                            height: '140px',
                                            width: '140px',
                                            objectFit: 'contain',
                                        }}
                                        alt='logo_preview'
                                    />
                                )}
                            </div>

                            <Grid item>
                                <CssTextField
                                    id='url-input'
                                    name='url'
                                    label='Url'
                                    type='text'
                                    value={formValues.url}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    id='android-input'
                                    name='android_app'
                                    label='Android app'
                                    type='text'
                                    value={formValues.android_app}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    id='ios-input'
                                    name='ios_app'
                                    label='IOS app'
                                    type='text'
                                    value={formValues.ios_app}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    id='windows-input'
                                    name='windows_app'
                                    label='Windows app'
                                    type='text'
                                    value={formValues.windows_app}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl
                                    {...(error.find(e => e.key === 'category_id') && {
                                        error: true,
                                    })}
                                    sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel
                                        sx={{
                                            // color gray and italic
                                            color: 'gray',
                                            fontStyle: 'italic',
                                            // color black when the user clicks on the input
                                            '&.Mui-focused': {
                                                color: 'black',
                                                fontStyle: 'italic',
                                            },
                                        }}
                                        id='Category_label_input'>
                                        Category
                                    </InputLabel>
                                    <CssSelect
                                        labelId='Category_label_input'
                                        id='category_id'
                                        name='category_id'
                                        value={formValues.category_name}
                                        onChange={handleCategoryChange}>
                                        {categories.length > 0 &&
                                            categories.map((category, index) => (
                                                <MenuItem key={index} value={category.name}>
                                                    {category.name}
                                                </MenuItem>
                                            ))}
                                    </CssSelect>
                                    {error.find(e => e.key === 'category_id') && (
                                        <CssFormHelperText
                                            sx={{
                                                color: 'red',
                                                fontSize: '16px',
                                            }}>
                                            {error.find(e => e.key === 'category_id').value}
                                        </CssFormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    id='techsupport-input'
                                    name='tech_support_saas'
                                    label='Technical support saas url'
                                    type='text'
                                    value={formValues.tech_support_saas}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel id='department_label'>Department</InputLabel>
                                <SelectReact
                                    styles={customStyles}
                                    options={department}
                                    onChange={handleChangeReactSelect}
                                    isMulti
                                    value={selected}
                                />
                                {error.find(e => e.key === 'department') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'department').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>
                            {
                                // if almost one field are different that the default value show the button else don't show the button
                                formValues.name !== defaultValues.name ||
                                formValues.name_fr !== defaultValues.name_fr ||
                                formValues.vendor !== defaultValues.vendor ||
                                formValues.logo !== defaultValues.logo ||
                                formValues.url !== defaultValues.url ||
                                formValues.android_app !== defaultValues.android_app ||
                                formValues.ios_app !== defaultValues.ios_app ||
                                formValues.windows_app !== defaultValues.windows_app ||
                                formValues.category_name !== defaultValues.category_name ||
                                formValues.tech_support_saas !== defaultValues.tech_support_saas ||
                                formValues.department_id !== defaultValues.department_id ? (
                                    <div
                                        style={{
                                            padding: '20px',
                                        }}>
                                        <Button type='submit' variant='contained' color='primary'>
                                            Update
                                        </Button>
                                    </div>
                                ) : (
                                    ''
                                )
                            }
                        </Grid>
                    </form>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '20px',
                        }}>
                        <Link
                            to='/admin'
                            style={{
                                textDecoration: 'none',
                            }}>
                            <Button variant='contained' color='error'>
                                Back to admin page
                            </Button>
                        </Link>
                    </div>
                </>
            ) : (
                <h1>Reserved for Admin</h1>
            )}
        </>
    );
};

export default UpdateApp;
