import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FormHelperText, InputLabel } from '@mui/material';
import { Typography } from '@mui/material';
import SelectReact from 'react-select';
import { styled } from '@mui/material/styles';
import { BaseURL } from '../../../constants/BaseUrl';

const CssFormHelperText = styled(FormHelperText)({
    color: 'red',
    fontSize: '1rem',
});

const CssTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: 'white',
    },
    // width of input field in create app form 400px
    width: '400px',
    '& label.Mui-focused': {
        color: 'black',
    },
    '& label': {
        color: 'black',
        fontStyle: 'italic',
    },
    '& .MuiInputLabel-outlined': {
        fontWeight: 'bold',

        fontSize: '17px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'gray',
        },
        '&:hover fieldset': {
            borderColor: 'white',
            border: '1px solid black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            border: '1px solid black',
        },
    },
});

// custom style react select to match the design of the app
const customStyles = {
    control: (base, state) => ({
        ...base,
        border: '1px solid gray',
        '&:hover': {
            border: '1px solid black',
        },
        '&:focus': {
            border: '1px solid black',
        },
        minheight: '56px',
        maxWidth: '400px',
        width: '400px',
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? 'black' : 'white',
        '&:hover': {
            backgroundColor: 'black',
            color: 'white',
        },
        // width size of the biggest size of the options
        width: '350px',
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'black',
    }),

    multiValue: (provided, state) => ({
        ...provided,

        backgroundColor: '#f5f5f5',
        color: 'black',

        border: '1px solid gray',
        // no display on the same line
    }),
};

const api = axios.create({
    baseURL: BaseURL,
});

const defaultValues = {
    name: '',
    name_fr: '',
    vendor: '',
};

const CreateContact = () => {
    const [formValues, setFormValues] = useState(defaultValues);
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState([]);
    const [department, setDepartment] = useState([]);
    const [selected, setSelected] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        // get all categories from db and set them in state to be used in the select menu for categories
        api.get('/categories').then(res => {
            setCategories(res.data);
        });
        api.get('/department').then(res => {
            // update state who for each department in the db department_id is label and id is value for use react select
            setDepartment(
                res.data.map(department => {
                    return {
                        label: department.name,
                        value: department.id,
                    };
                })
            );
        });
        if (localStorage.getItem('isAdmin') === 'true') {
            setIsAdmin(true);
        }
    }, []);

    const handleChangeReactSelect = selectedOption => {
        setSelected(selectedOption);
    };

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    console.log(formValues);
    console.log(error);

    const handleSubmit = e => {
        e.preventDefault();

        if (formValues.name === '') {
            // setError in array with key and value to be used in the form to display the error
            return setError([{ key: 'name', value: 'App name is required' }]);
        }
        if (formValues.vendor === '') {
            return setError([{ key: 'vendor', value: 'Vendor is required' }]);
        }
        if (selected.length === 0) {
            return setError([{ key: 'department', value: 'Department is required' }]);
        }

        if (formValues.name !== '' && formValues.vendor !== '' && selected.length !== 0) {
            // check if the name in not already in the db if not create the contact in the db and reset the formValues to default values else display error message that the name already exists
            api.get(`/contact/name/${formValues.name}`).then(res => {
                if (res.data.length === 0) {
                    api.post('/contact', formValues)
                        .then(res => {
                            // if res alert success and reset formValues to default values
                            if (res.status === 200) {
                                alert('Contact created');
                                setFormValues(defaultValues);
                                return res;
                            }
                        })
                        .then(res => {
                            // if res add the contact to the department
                            if (res.status === 200) {
                                selected.forEach(selectedValue => {
                                    api.post('/department_contact', {
                                        contact_id: res.data.insertId,
                                        department_id: selectedValue.value,
                                    });
                                    setSelected([]);
                                });
                            }
                        });
                    // reset the error state of name_already
                    setError([]);
                } else {
                    alert('Contact already exists');
                    setError([{ key: 'name', value: 'Contact already exists' }]);
                }
            });
        }
    };

    return (
        <>
            {isAdmin ? (
                <div id='create_contact'>
                    <Typography
                        variant='h4'
                        sx={{
                            mb: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '35px',
                        }}>
                        Create Contact
                    </Typography>
                    <form
                        onSubmit={handleSubmit}
                        style={{
                            paddingTop: '50px',
                        }}>
                        <Grid
                            container
                            alignItems='center'
                            justify='center'
                            direction='column'
                            spacing={2}>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(e => e.key === 'name') && {
                                        error: true,
                                    })}
                                    key={1}
                                    id='name-input'
                                    name='name'
                                    label='Name'
                                    type='text'
                                    value={formValues.name}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'name') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'name').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    key={2}
                                    id='namefr-input'
                                    name='name_fr'
                                    label='Name FR'
                                    type='text'
                                    value={formValues.name_fr}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(e => e.key === 'vendor') && {
                                        error: true,
                                    })}
                                    key={3}
                                    id='vendor-input'
                                    name='vendor'
                                    label='Vendor'
                                    type='text'
                                    value={formValues.vendor}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'vendor') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'vendor').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    maxWidth: 250,
                                    minWidth: 250,
                                }}>
                                <InputLabel
                                    id='demo-mutiple-chip-label'
                                    sx={{
                                        textAlign: 'center',
                                        color: 'black',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}>
                                    Departments
                                </InputLabel>
                                <SelectReact
                                    styles={customStyles}
                                    options={department}
                                    onChange={handleChangeReactSelect}
                                    isMulti
                                    value={selected}
                                    placeholder='Select Department...'
                                />
                                {error.find(e => e.key === 'department') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'department').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>
                            {formValues.name !== defaultValues.name ||
                            formValues.name_fr !== defaultValues.name_fr ||
                            formValues.vendor !== defaultValues.vendor ||
                            formValues.department_id !== defaultValues.department_id ? (
                                <div
                                    style={{
                                        padding: '20px',
                                    }}>
                                    <Button type='submit' variant='contained' color='primary'>
                                        Submit
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </form>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '20px',
                        }}>
                        <Link
                            to='/admin'
                            style={{
                                textDecoration: 'none',
                            }}>
                            <Button variant='contained' color='error'>
                                Back to admin page
                            </Button>
                        </Link>
                    </div>
                </div>
            ) : (
                <h1>Reserved for Admin</h1>
            )}
        </>
    );
};

export default CreateContact;
