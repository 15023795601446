import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FormHelperText, InputLabel } from '@mui/material';
import { Typography } from '@mui/material';
import SelectReact from 'react-select';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { BaseURL } from '../../../constants/BaseUrl';

const CssFormHelperText = styled(FormHelperText)({
    color: 'red',
    fontSize: '1rem',
});

const CssTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: 'white',
    },
    // width of input field in create app form 400px
    width: '400px',
    '& label.Mui-focused': {
        color: 'black',
    },
    '& label': {
        color: 'black',
        fontStyle: 'italic',
    },
    '& .MuiInputLabel-outlined': {
        fontWeight: 'bold',

        fontSize: '17px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'gray',
        },
        '&:hover fieldset': {
            borderColor: 'white',
            border: '1px solid black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            border: '1px solid black',
        },
    },
});

// custom style react select to match the design of the app
const customStyles = {
    control: (base, state) => ({
        ...base,
        border: '1px solid gray',
        '&:hover': {
            border: '1px solid black',
        },
        '&:focus': {
            border: '1px solid black',
        },
        minheight: '56px',
        maxWidth: '400px',
        width: '400px',
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? 'black' : 'white',
        '&:hover': {
            backgroundColor: 'black',
            color: 'white',
        },
        // width size of the biggest size of the options
        width: '350px',
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'black',
    }),

    multiValue: (provided, state) => ({
        ...provided,

        backgroundColor: '#f5f5f5',
        color: 'black',

        border: '1px solid gray',
        // no display on the same line
    }),
};

const api = axios.create({
    baseURL: BaseURL,
});

const defaultValues = {
    name: '',
    name_fr: '',
    vendor: '',
};

const UpdateContact = () => {
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(defaultValues);
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState([]);
    const [department, setDepartment] = useState([]);
    const [selected, setSelected] = useState([]);
    const { id } = useParams();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const res = await api.get(`/contact/${id}`);
            const selectedDepartments = res.data.map(department => {
                return { value: department.department_id, label: department.department_name };
            });
            setSelected(selectedDepartments);
            setFormValues(res.data[0]);
        };
        fetchData();

        api.get('/categories').then(res => {
            setCategories(res.data);
        });
        api.get('/department').then(res => {
            // update state who for each department in the db department_id is label and id is value for use react select
            setDepartment(
                res.data.map(department => {
                    return {
                        label: department.name,
                        value: department.id,
                    };
                })
            );
        });
        if (localStorage.getItem('isAdmin') === 'true') {
            setIsAdmin(true);
        }
    }, [id]);

    const handleChangeReactSelect = selectedOption => {
        setSelected(selectedOption);
    };

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    // console.log(formValues);

    const handleSubmit = e => {
        e.preventDefault();
        // console.log('toto' + selected);
        if (formValues.name === '') {
            // setError in array with key and value to be used in the form to display the error
            return setError([{ key: 'name', value: 'App name is required' }]);
        }
        if (formValues.vendor === '') {
            return setError([{ key: 'vendor', value: 'Vendor is required' }]);
        }
        if (selected.length === 0) {
            return setError([{ key: 'department', value: 'Department is required' }]);
        }

        // if all the required fields are filled, reset error state and submit the form
        if (formValues.name !== '' && formValues.vendor !== '' && selected.length !== 0) {
            setError([]);
            api.put(`/contact/${id}`, {
                ...formValues,
            }).then(res => {
                if (res.status === 200) {
                    api.get(`/department_contact/contact/${id}`)
                        .then(res => {
                            res.data.forEach(contact_department => {
                                if (
                                    !selected.find(
                                        department =>
                                            department.value === contact_department.department_id
                                    )
                                ) {
                                    api.delete(
                                        `/department_contact/${id}/${contact_department.department_id}`
                                    );
                                }
                            });
                            selected.forEach(department => {
                                if (
                                    !res.data.find(
                                        contact_department =>
                                            contact_department.department_id === department.value
                                    )
                                ) {
                                    api.post('/department_contact', {
                                        contact_id: id,
                                        department_id: department.value,
                                    });
                                }
                            });
                        })
                        .then(() => {
                            alert('App updated successfully');
                            navigate(`/admin`);
                        })
                        .catch(err => console.log(err));
                }
            });
        }
    };

    return (
        <>
            {isAdmin ? (
                <>
                    <Typography
                        variant='h4'
                        sx={{
                            mb: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '35px',
                        }}>
                        Update Contact
                    </Typography>
                    <form
                        style={{
                            paddingTop: '50px',
                        }}
                        onSubmit={handleSubmit}>
                        <Grid
                            container
                            alignItems='center'
                            justify='center'
                            direction='column'
                            spacing={2}>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(err => err.key === 'name') && {
                                        error: true,
                                    })}
                                    id='name-input'
                                    name='name'
                                    label='Name'
                                    type='text'
                                    value={formValues.name}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'name') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'name').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    id='namefr-input'
                                    name='name_fr'
                                    label='Name FR'
                                    type='text'
                                    value={formValues.name_fr}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item>
                                <CssTextField
                                    {...(error.find(err => err.key === 'vendor') && {
                                        error: true,
                                    })}
                                    id='vendor-input'
                                    name='vendor'
                                    label='Vendor'
                                    type='text'
                                    value={formValues.vendor}
                                    onChange={handleInputChange}
                                />
                                {error.find(e => e.key === 'vendor') && (
                                    <CssFormHelperText>
                                        {error.find(e => e.key === 'vendor').value}
                                    </CssFormHelperText>
                                )}
                            </Grid>

                            <Grid item>
                                <InputLabel
                                    sx={{
                                        textAlign: 'center',
                                        color: 'black',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}
                                    id='department_label'>
                                    Department
                                </InputLabel>
                                <SelectReact
                                    styles={customStyles}
                                    options={department}
                                    onChange={handleChangeReactSelect}
                                    isMulti
                                    value={selected}
                                />
                            </Grid>

                            {error.find(e => e.key === 'department') && (
                                <CssFormHelperText>
                                    {error.find(e => e.key === 'department').value}
                                </CssFormHelperText>
                            )}
                            {
                                // if almost one field are different that the default value show the button else don't show the button
                                formValues.name !== defaultValues.name ||
                                formValues.name_fr !== defaultValues.name_fr ||
                                formValues.vendor !== defaultValues.vendor ||
                                formValues.department_id !== defaultValues.department_id ? (
                                    <div
                                        style={{
                                            padding: '20px',
                                        }}>
                                        <Button type='submit' variant='contained' color='primary'>
                                            Update
                                        </Button>
                                    </div>
                                ) : (
                                    ''
                                )
                            }
                        </Grid>
                    </form>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '20px',
                        }}>
                        <Link
                            to='/admin'
                            style={{
                                textDecoration: 'none',
                            }}>
                            <Button variant='contained' color='error'>
                                Back to admin page
                            </Button>
                        </Link>
                    </div>
                </>
            ) : (
                <h1>Reserved for Admin</h1>
            )}
        </>
    );
};

export default UpdateContact;
