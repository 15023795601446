import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import Button from '@mui/material/Button';

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

export const SignInButton = () => {
    const { instance } = useMsal();

    useEffect(() => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }, []);

    return (
        <div>
            <Button
                variant='contained'
                onClick={() => handleLogin(instance)}
                sx={{
                    marginLeft: 'auto',
                    marginRight: 0,
                    display: 'flex',
                }}>
                Login
            </Button>
        </div>
    );
};
