import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';

function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <div>
            <Button
                sx={{
                    marginLeft: 'auto',
                    marginRight: 0,
                    display: 'flex',
                }}
                variant='contained'
                onClick={() => handleLogout(instance)}>
                Logout
            </Button>
        </div>
    );
};
