import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import Layout from '../Layout';
import { Button, Typography } from '@mui/material';
import AppTable from './TableData/AppTable';
import ContactTable from './TableData/ContactTable';
import TechnicalSupportTable from './TableData/TechnicalSupportTable';
import SaleSupportTable from './TableData/SaleSupportTable';
import ContactSupportTable from './TableData/ContactSupportTable';
import DepartmentTable from './TableData/DepartmentTable';
import CategoryTable from './TableData/CategoryTable';

const AdminPanel = ({ isAdmin }) => {
    return (
        <Layout>
            {isAdmin ? (
                <>
                    <Typography
                        variant='h1'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '30px',
                        }}>
                        Admin Panel
                    </Typography>
                    <div className='admin-panel-links'>
                        <Link
                            style={{ textDecoration: 'none', padding: 20 }}
                            to='/apps/create'
                            state={{ isAdmin: isAdmin }}>
                            <Button variant='contained'>Add App</Button>
                        </Link>
                        <Link
                            style={{ textDecoration: 'none', padding: 20 }}
                            to='/technicalsupport/create'>
                            <Button variant='contained'>Add Technical Support App</Button>
                        </Link>
                        <Link
                            style={{ textDecoration: 'none', padding: 20 }}
                            to='/salesupport/create'>
                            <Button variant='contained'>Add Sale Support App</Button>
                        </Link>
                        <Link style={{ textDecoration: 'none', padding: 20 }} to='/contact/create'>
                            <Button variant='contained'>Add Service Provider</Button>
                        </Link>
                        <Link
                            style={{ textDecoration: 'none', padding: 20 }}
                            to='/contact_support/create'>
                            <Button variant='contained'>Add Service Provider Contact</Button>
                        </Link>
                        <Link
                            style={{ textDecoration: 'none', padding: 20 }}
                            to='/department/create'>
                            <Button variant='contained'>Add Department</Button>
                        </Link>
                        <Link style={{ textDecoration: 'none', padding: 20 }} to='/category/create'>
                            <Button variant='contained'>Add Category</Button>
                        </Link>
                    </div>
                    <Typography
                        variant='h3'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '30px',
                            paddingBottom: '30px',
                        }}>
                        Overview
                    </Typography>

                    <div
                        style={{
                            paddingBottom: '30px',
                        }}>
                        <AppTable />
                    </div>
                    <div
                        style={{
                            paddingBottom: '30px',
                        }}>
                        <TechnicalSupportTable />
                    </div>
                    <div
                        style={{
                            paddingBottom: '30px',
                        }}>
                        <SaleSupportTable />
                    </div>
                    <div
                        style={{
                            paddingBottom: '30px',
                        }}>
                        <ContactTable />
                    </div>
                    <div
                        style={{
                            paddingBottom: '30px',
                        }}>
                        <ContactSupportTable />
                    </div>
                    <div
                        style={{
                            paddingBottom: '30px',
                        }}>
                        <DepartmentTable />
                    </div>
                    <div
                        id='categories'
                        style={{
                            paddingBottom: '30px',
                        }}>
                        <CategoryTable />
                    </div>
                </>
            ) : (
                <h1>Reserved for Admin</h1>
            )}
        </Layout>
    );
};

export default AdminPanel;
