import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export const ContactButton = () => {
    return (
        <div
            style={{
                width: 'auto',
                paddingRight: '1rem',
                paddingLeft: '1rem',
            }}>
            <Link to='/contact' style={{ textDecoration: 'none' }}>
                <Button
                    sx={{
                        marginLeft: 'auto',
                        marginRight: 0,
                        display: 'flex',
                    }}
                    variant='contained'
                    onClick={() => '#'}>
                    Contact
                </Button>
            </Link>
        </div>
    );
};
