function language() {
    const langBrowser = navigator.language;
    let langUser = 'en';
    if (langBrowser === 'fr-FR') {
        langUser = 'fr';
    }
    if (langBrowser === 'fr') {
        langUser = 'fr';
    }
    return langUser;
}

export default language;
