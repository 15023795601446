import create from 'zustand';

// const UserStore = create(set => ({
//     users: Users,
// }));
const UserStore = create(set => ({
    users: '',
    addUser: user =>
        set(state => ({
            users: [...state.users, user],
        })),
    // update user with value groupMember from the response of getMemberOf
    updateUser: userData => {
        set(state => ({
            users: state.users.map(u => {
                if (u.id) {
                    // return user modified with value groupMember
                    return {
                        ...u,
                        // add new entry in state objet with value departement
                        groupMember: userData.groupMember,
                    };
                }
                return u;
            }),
        }));
    },
    updateUserDepartement: userData => {
        set(state => ({
            users: state.users.map(u => {
                if (u.id) {
                    // return user modified with value groupMember
                    return {
                        ...u,
                        // add new entry in state objet with value departement

                        department: userData.department,
                    };
                }
                return u;
            }),
        }));
    },
}));

export default UserStore;
