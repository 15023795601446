import UserStore from './store/UserStore.js';
import { Routes, Route, Navigate } from 'react-router-dom';
import ContactList from './Components/ContactList.js';
import CreateApp from './Components/admin/app/CreateApp.js';
import UpdateApp from './Components/admin/app/UpdateApp.js';
import AdminPanel from './Components/admin/AdminPanel.js';
import CreateSaleSupport from './Components/admin/app/support/CreateSaleSupport.js';
import UpdateSaleSupport from './Components/admin/app/support/UpdateSaleSupport.js';
import CreateTechnicalSupport from './Components/admin/app/support/CreateTechnicalSupport.js';
import UpdateTechnicalSupport from './Components/admin/app/support/UpdateTechnicalSupport.js';
import CreateDepartment from './Components/admin/department/CreateDepartment.js';
import UpdateDepartment from './Components/admin/department/UpdateDepartment.js';
import ViewApp from './Components/admin/app/ViewApp.js';
import AppsList from './Components/AppsList.js';
import CreateContact from './Components/admin/contact/CreateContact.js';
import UpdateContact from './Components/admin/contact/UpdateContact.js';
import CreateContactSupport from './Components/admin/contact/contact_support/CreateContactSupport.js';
import UpdateContactSupport from './Components/admin/contact/contact_support/UpdateContactSupport.js';
import CreateCategory from './Components/admin/category/CreateCategory.js';
import UpdateCategory from './Components/admin/category/UpdateCategory.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdminGroup } from './constants/AdminGroup.js';
import AppsListFavorite from './Components/AppsListFavorite.js';

const theme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

function App() {
    const users = UserStore(state => state.users);

    const isAdmin =
        users[0] &&
        users[0].groupMember &&
        users[0].groupMember.some(group => group.id === AdminGroup);

    return (
        <div className='' style={{ height: '100%' }}>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path='/' element={<AppsList users={users} />} />
                    <Route path='contact' element={<ContactList />} />
                    <Route path='/apps/create' element={<CreateApp isAdmin={isAdmin} />} />
                    <Route path='/apps/:id/update' element={<UpdateApp isAdmin={isAdmin} />} />
                    <Route path='/apps/:id/view' element={<ViewApp isAdmin={isAdmin} />} />
                    <Route
                        path='/salesupport/create'
                        element={<CreateSaleSupport isAdmin={isAdmin} />}
                    />
                    <Route
                        path='/salesupport/:id/update'
                        element={<UpdateSaleSupport isAdmin={isAdmin} />}
                    />
                    <Route
                        path='/technicalsupport/create'
                        element={<CreateTechnicalSupport isAdmin={isAdmin} />}
                    />
                    <Route
                        path='/technicalsupport/:id/update'
                        element={<UpdateTechnicalSupport isAdmin={isAdmin} />}
                    />
                    <Route path='/admin' element={<AdminPanel user={users} isAdmin={isAdmin} />} />
                    <Route
                        path='/department/create'
                        element={<CreateDepartment isAdmin={isAdmin} />}
                    />
                    <Route
                        path='/department/:id/update'
                        element={<UpdateDepartment isAdmin={isAdmin} />}
                    />
                    <Route path='/category/create' element={<CreateCategory isAdmin={isAdmin} />} />
                    <Route
                        path='/categories/:id/update'
                        element={<UpdateCategory isAdmin={isAdmin} />}
                    />
                    <Route path='/contact/create' element={<CreateContact isAdmin={isAdmin} />} />
                    <Route
                        path='/contact/:id/update'
                        element={<UpdateContact isAdmin={isAdmin} />}
                    />
                    <Route
                        path='/contact_support/create'
                        element={<CreateContactSupport isAdmin={isAdmin} />}
                    />
                    <Route
                        path='/contact_support/:id/update'
                        element={<UpdateContactSupport isAdmin={isAdmin} />}
                    />
                    <Route path='/favorites' element={<AppsListFavorite isAdmin={isAdmin} />} />
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
            </ThemeProvider>
        </div>
    );
}

export default App;
