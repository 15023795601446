import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import axios from 'axios';

import { urlFormGLPI } from '../constants/glpi';
import { BaseURL } from '../constants/BaseUrl';
import language from '../utils/language';

const api = axios.create({
    baseURL: BaseURL,
});

const ContactCard = ({ contact, user }) => {
    console.log(contact);
    const [openSale, setOpenSale] = useState(false);
    const [contactSupport, setContactSupport] = useState([]);
    const [langBrowser, setlangBrowser] = useState('en');

    useEffect(() => {
        let lang = language();
        setlangBrowser(lang);
        const fetchData = async () => {
            api.get(`/contact_support/contact/${contact.id}`).then(res => {
                setContactSupport(res.data);
            });
        };
        fetchData();
    }, []);

    console.log(contactSupport);

    const handleClickSale = () => {
        setOpenSale(!openSale);
    };
    return (
        <>
            <Grid item xs={2} style={{ display: 'flex' }}>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        width: '100%',
                        minHeight: 100,
                    }}>
                    <CardContent>
                        <Typography variant='h5' component='div'>
                            {langBrowser === 'fr' && contact.name_fr != ''
                                ? contact.name_fr
                                : contact.name}
                        </Typography>
                        <Typography
                            sx={{
                                mt: 1.5,
                            }}
                            color='text.secondary'>
                            {contact.vendor}
                        </Typography>
                    </CardContent>
                    <div>
                        <List>
                            <ListItem
                                sx={{
                                    display: 'block',
                                }}>
                                <ListItemButton onClick={handleClickSale}>
                                    <ListItemText
                                        primary={
                                            langBrowser === 'fr'
                                                ? ' Contact Commercial'
                                                : 'Commercial Contact'
                                        }
                                    />
                                    {openSale ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openSale} timeout='auto' unmountOnExit>
                                    <List component='div' disablePadding>
                                        {contactSupport.map(contactsupport => (
                                            <>
                                                <Typography
                                                    variant='body1'
                                                    sx={{
                                                        padding: '10px',
                                                        fontWeight: 'bold',
                                                    }}>
                                                    {contactsupport.first_name}{' '}
                                                    {contactsupport.last_name}
                                                </Typography>
                                                <div
                                                    style={{
                                                        display: 'block',
                                                    }}>
                                                    <List>
                                                        {contactsupport.email && (
                                                            <>
                                                                <ListItemText
                                                                    primary={contactsupport.email}
                                                                />
                                                                <ListItem
                                                                    sx={{
                                                                        display: 'block',
                                                                    }}>
                                                                    <ListItemButton
                                                                        component='a'
                                                                        href={
                                                                            'mailto:' +
                                                                            contactsupport.email
                                                                        }>
                                                                        <ListItemIcon
                                                                            sx={{
                                                                                margin: 'auto',
                                                                            }}>
                                                                            <EmailIcon />
                                                                        </ListItemIcon>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            </>
                                                        )}
                                                        <ListItem
                                                            sx={{
                                                                display: 'block',
                                                            }}>
                                                            <ListItemText primary='VTS' />
                                                            <ListItemButton
                                                                component='a'
                                                                target='_blank'
                                                                href={urlFormGLPI}>
                                                                <ListItemIcon
                                                                    sx={{
                                                                        margin: 'auto',
                                                                    }}>
                                                                    <ContactSupportIcon />
                                                                </ListItemIcon>
                                                            </ListItemButton>
                                                        </ListItem>
                                                        {contactsupport.phone && (
                                                            <>
                                                                <ListItem
                                                                    sx={{
                                                                        display: 'block',
                                                                    }}>
                                                                    <ListItemText
                                                                        primary={
                                                                            contactsupport.phone
                                                                        }
                                                                    />
                                                                    <ListItemButton
                                                                        component='a'
                                                                        target='_blank'
                                                                        href={
                                                                            'callto:' +
                                                                            contactsupport.phone
                                                                        }>
                                                                        <ListItemIcon
                                                                            component='a'
                                                                            target='_blank'
                                                                            href='#test'
                                                                            sx={{
                                                                                margin: 'auto',
                                                                            }}>
                                                                            <CallIcon />
                                                                        </ListItemIcon>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            </>
                                                        )}
                                                    </List>
                                                </div>
                                            </>
                                        ))}
                                    </List>
                                </Collapse>
                            </ListItem>
                        </List>
                    </div>
                </Card>
            </Grid>
        </>
    );
};

export default ContactCard;
